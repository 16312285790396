export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
]
